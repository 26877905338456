import styled from "styled-components"

export const Container = styled.section`
  display: flex;
  padding: 24px 24px;
  justify-content: center;
  min-height: 100vh;
  @media screen and (min-width: 769px) {
    height: 100vh;
  }
`

export const Row = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  grid-auto-columns: minmax(auto, 1fr);
  grid-template-areas: ${props => {
    return props.imgStart ? `'col2 col1'` : `'col1 col2'`
  }};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${props =>
      props.imgStart ? `'col2 col2' 'col1 col1'` : `'col1 col1' 'col2 col2'`};
  }
`

export const ColumnA = styled.div`
  grid-area: col1;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 15px;
`

export const ColumnB = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContentWrapper = styled.div`
  color: ${props => (props.lightText ? "#fafafa" : "#333")};
  width: 100%;
`

export const ImageWrapper = styled.div`
  height: 100%;
`

export const Heading = styled.h2`
  margin-bottom: 24px;
`

export const Description = styled.p`
  margin-bottom: 35px;
  text-align: justify;
`
