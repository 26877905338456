import React from "react"
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet"
import coordinates from "./coordinates.json"

const LocationMap = () => {
  return (
    <MapContainer
      center={[-26.759986464256123, -48.67465739920681]}
      zoom={25}
      style={{
        maxWidth: "550px",
        height: "50vh",
        width: "100vw",
        zIndex: 0,
      }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={[-26.759986464256123, -48.67465739920681]}>
        <Popup>Radiologia Odonto Digital</Popup>
      </Marker>
      <GeoJSON data={coordinates} />
    </MapContainer>
  )
}

export default LocationMap
