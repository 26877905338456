import React from "react"
import Seo from "components/seo"
import HeroSection from "components/HeroSection"
import CommonSection from "components/CommonSection"

import LocationMap from "components/LocationMap"
import { useWherePageDataQuery } from "hooks/useWherePageDataQuery"

const Index = () => {
  const { heroContent, mapSectionContent } = useWherePageDataQuery()

  return (
    <>
      <Seo title="Serviços" />
      <HeroSection {...heroContent} />
      <CommonSection {...mapSectionContent}>
        <LocationMap />
      </CommonSection>
    </>
  )
}

export default Index
