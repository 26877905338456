import { graphql, useStaticQuery } from "gatsby"

export const useWherePageDataQuery = () => {
  const data = useStaticQuery(graphql`
    query WherePageDataQuery {
      mdx(frontmatter: { type: { eq: "wherePage" } }) {
        frontmatter {
          heroContent {
            backgroundImage {
              pluginImage {
                childImageSharp {
                  gatsbyImageData(
                    quality: 90
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    formats: AUTO
                  )
                }
              }
              alt
            }
            subTitle
            title
          }
          mapSectionContent {
            id
            title
            description
          }
        }
      }
    }
  `)
  return data.mdx.frontmatter
}
